import jwt from "jwt-decode";

export const getToken = () => {
  const token = localStorage.getItem("token");
  return token ? `Bearer ${token}` : undefined;
};

export const getTokenDecoded = () => {
  const token = localStorage.getItem("token");

  if (!token) {
    return undefined;
  }

  return jwt(token);
};

export const actions = {
  SolicitudesVer: 100000,
  SolicitudesCrear: 100001,
  SolicitudesActualizarPago: 100002,
  SolicitudesActualizarEntrega: 100003,
  SolicitudesEliminar: 100004,
  SolicitudesTarjetasVer: 100005,
  SolicitudesTarjetasImprimir: 100006,
  SolicitudesArchivosVer: 100007,
  SolicitudesArchivosAgregar: 100008,
  SolicitudesArchivosEliminar: 100009,

  TarjetasVer: 101000,
  TarjetasImprimir: 101001,
  TarjetasExportarExcel: 101002,

  PrestadoresVer: 102000,
  PrestadoresCrear: 102001,
  PrestadoresEditar: 102002,
  PrestadoresEliminar: 102003,
  PrestadoresCredencialesEnviar: 102004,
  PrestadoresArchivosVer: 102005,
  PrestadoresArchivosAgregar: 102006,
  PrestadoresArchivosEliminar: 102007,

  UsuariosVer: 103000,
  UsuariosCrear: 103001,
  UsuariosEditar: 103002,
  UsuariosEliminar: 103003,

  PerfilesVer: 104000,
  PerfilesCrear: 104001,
  PerfilesEditar: 104002,
  PerfilesEliminar: 104003,

  AuditoriasVer: 105000,
};

export const hasPermission = (action) => {
  const token = localStorage.getItem("token");

  if (!token) {
    return false;
  }

  const payload = jwt(token);
  if (!payload.actions) {
    localStorage.removeItem("token");
    window.location.replace("/auth/login");
  }

  const actions = JSON.parse(payload.actions);
  return actions.includes(action);
};

export const getAction = (id, create, edit, readonly) => {
  if (readonly) {
    return "Ver";
  }

  if (id === "nuevo") {
    return create ? "Crear" : "Ver";
  }

  return edit ? "Editar" : "Ver";
};
