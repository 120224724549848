/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { formatCurrency } from "./../../services/formatters";
import http from "../../services/http";
import { actions, hasPermission } from "../../services/security";

import classes from "./solicitudes-pagos.module.scss";

import icon from "./../../assets/images/pagos.png";

const SolicitudesPagos = (props) => {
  const { id } = props;

  const VER = hasPermission(actions.SolicitudesTarjetasVer);

  const [pagos, setPagos] = useState([]);

  useEffect(() => {
    async function getForm() {
      if (id === "nuevo" || !VER) {
        return;
      }

      const response = await http.get(`solicitudes/${id}/pagos`);
      if (response) {
        const data = response.data;
        setPagos(data);
      }
    }
    getForm();
  }, [id]);

  if (id === "nuevo") {
    return <React.Fragment />;
  }

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.icon}>
          <img src={icon} alt="emails" />
        </div>
        <div className={classes.data}>
          <div className={classes.title}>Pagos</div>
          <div className={classes.description}>Lista de pagos recibidos</div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Cuit</th>
            <th>Fecha</th>
            <th>Actualizacion</th>
            <th>Importe</th>
          </tr>
        </thead>
        <tbody>
          {pagos.length === 0 && (
            <tr>
              <td colSpan={4}>
                <div className={classes.empty}>
                  <div className={classes.title}>Sin resultados</div>
                  <div className={classes.description}>
                    No se encontró ningún resultado
                  </div>
                </div>
              </td>
            </tr>
          )}

          {pagos.map((tarjeta, index) => (
            <tr key={index}>
              <td>{tarjeta.cuit}</td>
              <td>{tarjeta.fecha}</td>
              <td>{tarjeta.actualizacion}</td>
              <td>{formatCurrency(tarjeta.importe)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SolicitudesPagos;
