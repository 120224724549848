import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import {
  FiCheckCircle,
  FiEdit,
  FiPlus,
  FiSearch,
  FiTrash2,
  FiUser,
} from "react-icons/fi";

import { TableContextProvider } from "./../../store/table-context";
import { confirm, modalSuccess } from "../../services/notifications";
import http from "./../../services/http";
import { actions, hasPermission } from "../../services/security";

import Card from "./../../components/ui/card";
import Header from "../../components/ui/header";
import Filters from "./../../components/ui/filters";
import Table from "../../components/ui/table";
import UsuariosEstado from "./../../components/usuarios/usuarios-estado";

const UsuariosListPage = () => {
  let navigate = useNavigate();

  const VER = hasPermission(actions.UsuariosVer);
  const CREAR = hasPermission(actions.UsuariosCrear);
  const EDITAR = hasPermission(actions.UsuariosEditar);
  const ELIMINAR = hasPermission(actions.UsuariosEliminar);

  const title = "Usuarios";
  const icon = <FiUser />;
  const breadcrumb = [{ title: "Usuarios", url: "/usuarios" }];

  const filters = [
    {
      type: "input",
      label: "Nombre",
      name: "nombre",
    },
    {
      type: "input",
      label: "Usuario",
      name: "username",
    },
    {
      type: "input",
      label: "Email",
      name: "email",
    },
    {
      type: "select",
      label: "Estado",
      name: "estado",
      values: [
        { value: "0", text: "Habilitado" },
        { value: "1", text: "Deshabilitado" },
      ],
    },
  ];

  const columns = [
    { title: "Nombre", property: "nombre", sortable: true },
    { title: "Usuario", property: "username", sortable: true },
    { title: "Email", property: "email", sortable: true },
    {
      title: "Estado",
      property: "habilitado",
      sortable: false,
      render: (item) => <UsuariosEstado estado={item.estado} />,
    },
  ];

  const menu = (item) => (
    <Menu>
      {VER && (
        <Menu.Item
          key="1"
          icon={<FiSearch />}
          onClick={() => onClickView(item)}
        >
          Ver
        </Menu.Item>
      )}
      {EDITAR && (
        <Menu.Item key="2" icon={<FiEdit />} onClick={() => onClickEdit(item)}>
          Editar
        </Menu.Item>
      )}
      {ELIMINAR && (
        <Menu.Item
          key="3"
          icon={item.estado === 0 ? <FiTrash2 /> : <FiCheckCircle />}
          onClick={() => onClickDelete(item)}
        >
          {item.estado === 0 ? "Deshabilitar" : "Habilitar"}
        </Menu.Item>
      )}
    </Menu>
  );

  const onClickAdd = () => {
    navigate("/usuarios/nuevo");
  };

  const onClickView = (item) => {
    navigate(`/usuarios/${item.id}?view=readonly`);
  };

  const onClickEdit = (item) => {
    navigate(`/usuarios/${item.id}`);
  };

  const onClickDelete = async (item) => {
    const action = item.estado === 0 ? "Deshabilitar" : "Habilitar";
    const result = item.estado === 0 ? "deshabilitado" : "habilitado";

    const confirmed = await confirm(
      `${action} usuario`,
      `¿Esta seguro que desea ${action.toLowerCase()} al usuario ${
        item.nombre
      }?`
    );

    if (!confirmed) {
      return;
    }

    const response = await http.delete(`usuarios/${item.id}`);
    if (response) {
      await modalSuccess(
        `Usuario ${result}`,
        `El usuario fue ${result} exitosamente`
      );
      navigate(0);
    }
  };

  const buttons = [
    {
      title: "Agregar Usuario",
      text: <FiPlus />,
      type: "primary",
      onClick: onClickAdd,
      visible: CREAR,
    },
  ];

  return (
    <TableContextProvider>
      <Card>
        <Header
          title={title}
          icon={icon}
          breadcrumb={breadcrumb}
          showFilters
          buttons={buttons}
        />
        <Filters fields={filters} />
        <Table
          id="table-usuarios"
          columns={columns}
          menu={menu}
          url="/usuarios"
          orderBy="nombre"
          orderDirection="ascending"
        />
      </Card>
    </TableContextProvider>
  );
};

export default UsuariosListPage;
