/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { FiMonitor, FiSearch } from "react-icons/fi";

import { TableContextProvider } from "./../../store/table-context";

import Card from "./../../components/ui/card";
import Header from "../../components/ui/header";
import Filters from "./../../components/ui/filters";
import Table from "../../components/ui/table";
import { actions, hasPermission } from "../../services/security";

const AuditoriasListPage = () => {
  let navigate = useNavigate();

  const VER = hasPermission(actions.AuditoriasVer);

  const title = "Auditorías";
  const icon = <FiMonitor />;
  const breadcrumb = [{ title: "Auditorías", url: "/auditorias" }];

  const filters = [
    {
      type: "date",
      label: "Fecha Desde",
      name: "desde",
      half: true,
    },
    {
      type: "date",
      label: "Fecha Hasta",
      name: "hasta",
      half: true,
    },
    {
      type: "select",
      label: "Acción",
      name: "accion",
      values: [
        {
          value: actions.SolicitudesVer,
          text: "Solicitudes - Ver",
        },
        {
          value: actions.SolicitudesCrear,
          text: "Solicitudes - Crear",
        },
        {
          value: actions.SolicitudesActualizarPago,
          text: "Solicitudes - Actualizar Pago",
        },
        {
          value: actions.SolicitudesActualizarEntrega,
          text: "Solicitudes - Actualizar Entrega",
        },
        {
          value: actions.SolicitudesEliminar,
          text: "Solicitudes - Eliminar",
        },
        {
          value: actions.SolicitudesTarjetasVer,
          text: "Solicitudes - Ver Tarjetas",
        },
        {
          value: actions.SolicitudesTarjetasImprimir,
          text: "Solicitudes - Imprimir Tarjetas",
        },
        {
          value: actions.SolicitudesArchivosVer,
          text: "Solicitudes - Ver Archivos",
        },
        {
          value: actions.SolicitudesArchivosAgregar,
          text: "Solicitudes - Agregar Archivos",
        },
        {
          value: actions.SolicitudesArchivosEliminar,
          text: "Solicitudes - Eliminar Archivos",
        },
        {
          value: actions.TarjetasVer,
          text: "Tarjetas - Ver",
        },
        {
          value: actions.TarjetasImprimir,
          text: "Tarjetas - Imprimir",
        },
        {
          value: actions.PrestadoresVer,
          text: "Prestadores - Ver",
        },
        {
          value: actions.PrestadoresCrear,
          text: "Prestadores - Crear",
        },
        {
          value: actions.PrestadoresEditar,
          text: "Prestadores - Editar",
        },
        {
          value: actions.PrestadoresEliminar,
          text: "Prestadores - Eliminar",
        },
        {
          value: actions.PrestadoresCredencialesEnviar,
          text: "Prestadores - Enviar Credenciales",
        },
        {
          value: actions.PrestadoresArchivosVer,
          text: "Prestadores - Ver Archivos",
        },
        {
          value: actions.PrestadoresArchivosAgregar,
          text: "Prestadores - Agregar Archivos",
        },
        {
          value: actions.PrestadoresArchivosEliminar,
          text: "Prestadores - Eliminar Archivos",
        },
        {
          value: actions.UsuariosVer,
          text: "Usuarios - Ver",
        },
        {
          value: actions.UsuariosCrear,
          text: "Usuarios - Crear",
        },
        {
          value: actions.UsuariosEditar,
          text: "Usuarios - Editar",
        },
        {
          value: actions.UsuariosEliminar,
          text: "Usuarios - Eliminar",
        },
        {
          value: actions.PerfilesVer,
          text: "Perfiles - Ver",
        },
        {
          value: actions.PerfilesCrear,
          text: "Perfiles - Crear",
        },
        {
          value: actions.PerfilesEditar,
          text: "Perfiles - Editar",
        },
        {
          value: actions.PerfilesEliminar,
          text: "Perfiles - Eliminar",
        },
        {
          value: actions.AuditoriasVer,
          text: "Auditorias - Ver",
        },
      ],
    },
    {
      type: "input",
      label: "Usuario",
      name: "usuario",
    },
  ];

  const columns = [
    { title: "Fecha", property: "fecha", sortable: false },
    { title: "Hora", property: "hora", sortable: false },
    { title: "Acción", property: "accion", sortable: false },
    { title: "Usuario", property: "usuario", sortable: false },
  ];

  const menu = (item) => (
    <Menu>
      {VER && (
        <Menu.Item
          key="1"
          icon={<FiSearch />}
          onClick={() => onClickView(item)}
        >
          Ver
        </Menu.Item>
      )}
    </Menu>
  );

  const onClickView = (item) => {
    navigate(`/auditorias/${item.id}?view=readonly`);
  };

  return (
    <TableContextProvider>
      <Card>
        <Header title={title} icon={icon} breadcrumb={breadcrumb} showFilters />
        <Filters fields={filters} />
        <Table
          id="table-auditorias"
          columns={columns}
          menu={menu}
          url="/auditorias"
          orderBy="fecha"
          orderDirection="descending"
        />
      </Card>
    </TableContextProvider>
  );
};

export default AuditoriasListPage;
