import { createContext, useState } from "react";

const TableContext = createContext({
  filtersVisible: false,
  filters: {},
  orderBy: "",
  orderDirection: "ascending",
  page: 1,
  size: 10,
  total: 0,

  updateFiltersVisible: (showFilters) => {},
  updateFilters: (filters) => {},
  updateOrderBy: (value) => {},
  updateOrderDirection: (value) => {},
  updatePage: (value) => {},
  updateSize: (value) => {},
  updateTotal: (value) => {},
});

export function TableContextProvider(props) {
  // State
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [filters, setFilters] = useState({});
  const [orderBy, setOrderBy] = useState();
  const [orderDirection, setOrderDirection] = useState("ascending");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);

  // Methods
  const updateFiltersVisible = (visible) => {
    setFiltersVisible(visible);
  };

  const updateFilters = (filters) => {
    setFilters(filters);
  };

  const updateOrderBy = (value) => {
    setOrderBy(value);
  };

  const updateOrderDirection = (value) => {
    setOrderDirection(value);
  };

  const updatePage = (value) => {
    setPage(value);
  };

  const updateSize = (value) => {
    setSize(value);
  };

  const updateTotal = (value) => {
    setTotal(value);
  };

  const context = {
    filtersVisible: filtersVisible,
    filters: filters,
    orderBy: orderBy,
    orderDirection: orderDirection,
    page: page,
    size: size,
    total: total,

    updateFiltersVisible: updateFiltersVisible,
    updateFilters: updateFilters,
    updateOrderBy: updateOrderBy,
    updateOrderDirection: updateOrderDirection,
    updatePage: updatePage,
    updateSize: updateSize,
    updateTotal: updateTotal,
  };

  return (
    <TableContext.Provider value={context}>
      {props.children}
    </TableContext.Provider>
  );
}

export default TableContext;
