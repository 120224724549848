import { FiImage, FiInbox, FiMonitor, FiShield, FiUser } from "react-icons/fi";

import { actions, hasPermission } from "./../../services/security";

import InicioSection from "../../components/inicio/inicio-section";

const InicioPage = () => {
  const sections = [
    {
      title: "Extintores",
      links: [
        {
          title: "Solicitudes",
          url: "/solicitudes",
          icon: <FiInbox />,
          visible: hasPermission(actions.SolicitudesVer),
        },
        {
          title: "Tarjetas",
          url: "/tarjetas",
          icon: <FiImage />,
          visible: hasPermission(actions.TarjetasVer),
        },
        {
          title: "Prestadores",
          url: "/prestadores",
          icon: <FiUser />,
          visible: hasPermission(actions.PrestadoresVer),
        },
      ],
    },
    {
      title: "Seguridad",
      links: [
        {
          title: "Perfiles",
          url: "/perfiles",
          icon: <FiShield />,
          visible: hasPermission(actions.PerfilesVer),
        },
        {
          title: "Usuarios",
          url: "/usuarios",
          icon: <FiUser />,
          visible: hasPermission(actions.UsuariosVer),
        },
        {
          title: "Auditoría",
          url: "/auditorias",
          icon: <FiMonitor />,
          visible: hasPermission(actions.AuditoriasVer),
        },
      ],
    },
  ];

  return (
    <div>
      {sections.map(
        (section, index) =>
          section.links.filter((x) => x.visible).length > 0 && (
            <InicioSection key={index} item={section} />
          )
      )}
    </div>
  );
};

export default InicioPage;
