import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { FiImage, FiPrinter, FiSearch } from "react-icons/fi";
import { SiMicrosoftexcel } from "react-icons/si";

import { TableContextProvider } from "../../store/table-context";
import { downloadBase64 } from "../../services/files";
import http from "../../services/http";
import { actions, hasPermission } from "../../services/security";

import Card from "../../components/ui/card";
import Header from "../../components/ui/header";
import Filters from "../../components/ui/filters";
import Table from "../../components/ui/table";
import SolicitudesEstado from "./../../components/solicitudes/solicitudes-estado";

const TarjetasListPage = () => {
  let navigate = useNavigate();

  const IMPRIMIR = hasPermission(actions.TarjetasImprimir);
  const EXPORTAR_EXCEL = hasPermission(actions.TarjetasExportarExcel);

  const title = "Tarjetas";
  const icon = <FiImage />;
  const breadcrumb = [{ title: "Tarjetas", url: "/tarjetas" }];

  const filters = [
    {
      type: "input",
      label: "Número",
      name: "numero",
    },
    {
      type: "input",
      label: "Matafuego",
      name: "matafuego",
    },
    {
      type: "date",
      label: "Activación Desde",
      name: "desde",
      half: true,
    },
    {
      type: "date",
      label: "Activación Hasta",
      name: "hasta",
      half: true,
    },
    {
      type: "input",
      label: "Prestador",
      name: "prestador",
    },
    {
      type: "select",
      label: "Estado",
      name: "estado",
      values: [
        { value: "0", text: "Pendiente" },
        { value: "1", text: "Pagada" },
        { value: "2", text: "Entregada" },
        { value: "3", text: "Eliminada" },
      ],
    },
  ];

  const columns = [
    { title: "Número", property: "numero", sortable: true },
    { title: "Matafuego", property: "matafuego", sortable: true },
    { title: "Fecha Activación", property: "activacion", sortable: true },
    { title: "Prestador", property: "prestador", sortable: true },
    {
      title: "Estado",
      property: "estado",
      sortable: true,
      render: (item) => <SolicitudesEstado estado={item.estado} />,
    },
  ];

  const menu = (item) => (
    <Menu>
      <Menu.Item key="1" icon={<FiSearch />} onClick={() => onClickView(item)}>
        Ver
      </Menu.Item>
      {IMPRIMIR && (item.estado === 1 || item.estado === 2) && (
        <Menu.Item
          key="2"
          icon={<FiPrinter />}
          onClick={() => onClickPrint(item)}
        >
          Imprimir
        </Menu.Item>
      )}
    </Menu>
  );

  const onClickView = (item) => {
    navigate(`/tarjetas/${item.id}?view=readonly`);
  };

  const onClickPrint = async (item) => {
    const response = await http.get(`tarjetas/${item.id}/pdf/`);
    if (response) {
      const data = response.data;
      downloadBase64(data.nombre, data.contenido);
    }
  };

  const onClickExportExcel = async (params) => {
    let url = `tarjetas/excel?orderBy=${params.orderBy}&orderDirection=${params.orderDirection}`;
    for (const property in params.filters) {
      url += `&${property}=${params.filters[property] ?? ""}`;
    }

    const response = await http.get(url);
    if (response) {
      const data = response.data;
      downloadBase64(data.nombre, data.contenido);
    }
  };

  const buttons = [
    {
      title: "Exportar Excel",
      text: <SiMicrosoftexcel />,
      type: "primary",
      onClick: onClickExportExcel,
      visible: EXPORTAR_EXCEL,
    },
  ];

  return (
    <TableContextProvider>
      <Card>
        <Header
          title={title}
          icon={icon}
          breadcrumb={breadcrumb}
          showFilters
          buttons={buttons}
        />
        <Filters fields={filters} />
        <Table
          id="table-tarjetas"
          columns={columns}
          menu={menu}
          url="/tarjetas"
          orderBy="nombre"
          orderDirection="ascending"
        />
      </Card>
    </TableContextProvider>
  );
};

export default TarjetasListPage;
