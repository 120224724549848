/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Row } from "antd";

import { required } from "../../services/forms";
import http from "../../services/http";

const TarjetasForm = (props) => {
  let navigate = useNavigate();
  const [form] = Form.useForm();

  const { id, readonly } = props;

  const disabled = readonly || true;

  useEffect(() => {
    async function getForm() {
      if (id === "nuevo") {
        return;
      }

      const response = await http.get(`tarjetas/${id}`);
      if (response) {
        const data = response.data;
        form.setFieldsValue(data);
      }
    }
    getForm();
  }, [id]);

  const onClickBack = () => {
    navigate(-1);
  };

  return (
    <Form layout="vertical" form={form}>
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item label="Número" name="numero" rules={[required]}>
            <Input disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Matafuego" name="matafuego" rules={[required]}>
            <Input disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item
            label="Fecha de Activación"
            name="activacion"
            rules={[required]}
          >
            <Input disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Prestador" name="prestador" rules={[required]}>
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>

      <div className="actions">
        <Button type="text" onClick={onClickBack}>
          Volver
        </Button>
      </div>
    </Form>
  );
};

export default TarjetasForm;
