import { Tag } from "antd";

const PrestadoresEstado = (props) => {
  const { className, estado } = props;

  let color;
  let text;

  switch (estado) {
    case 0:
      color = "green";
      text = "Habilitado";
      break;

    case 1:
      color = "red";
      text = "Deshabilitado";
      break;

    default:
      break;
  }

  return (
    <Tag className={className} color={color}>
      {text}
    </Tag>
  );
};

export default PrestadoresEstado;
