import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { FiUser } from "react-icons/fi";

import { actions, getAction, hasPermission } from "../../services/security";

import Card from "../../components/ui/card";
import PrestadoresForm from "../../components/prestadores/prestadores-form";
import Header from "../../components/ui/header";
import PrestadoresArchivos from "../../components/prestadores/prestadores-archivos";

const PrestadoresDetailPage = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const CREAR = hasPermission(actions.PrestadoresCrear);
  const EDITAR = hasPermission(actions.PrestadoresEditar);
  const readonly = searchParams.get("view") === "readonly";

  const action = getAction(id, CREAR, EDITAR, readonly);
  const title = `${action} Prestador`;
  const icon = <FiUser />;
  const breadcrumb = [
    { title: "Prestadores", url: "/prestadores" },
    { title: action, url: `/prestadores/${id}` },
  ];

  return (
    <React.Fragment>
      <Card>
        <Header title={title} icon={icon} breadcrumb={breadcrumb} />
        <PrestadoresForm id={id} readonly={readonly} />
      </Card>
      <Card>
        <PrestadoresArchivos id={id} readonly={readonly} />
      </Card>
    </React.Fragment>
  );
};

export default PrestadoresDetailPage;
