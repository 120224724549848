/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, InputNumber, Row, Select } from "antd";

import { required } from "../../services/forms";
import http from "../../services/http";
import { modalSuccess } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";

const SolicitudesForm = (props) => {
  let navigate = useNavigate();
  const [form] = Form.useForm();

  const { id, readonly } = props;

  const [prestadores, setPrestadores] = useState([]);

  const CREAR = hasPermission(actions.SolicitudesCrear);
  const EDTIAR = false;
  const disabled = readonly || (id === "nuevo" ? !CREAR : !EDTIAR);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getForm() {
      form.setFieldsValue({ numero: "-" });

      let response = await http.get("prestadores");
      if (response) {
        const data = response.data;
        setPrestadores(data.list);
      }

      if (id === "nuevo") {
        return;
      }

      response = await http.get(`solicitudes/${id}`);
      if (response) {
        const data = response.data;
        form.setFieldsValue(data);
      }
    }
    getForm();
  }, [id]);

  const onClickBack = () => {
    navigate(-1);
  };

  const onClickSave = async (values) => {
    setLoading(true);

    const response =
      id === "nuevo"
        ? await http.post("solicitudes", values)
        : await http.put(`solicitudes/${id}`, values);

    setLoading(false);

    if (response) {
      await modalSuccess(
        "Solicitud guardada",
        id === "nuevo"
          ? "La solicitud fue creada exitosamente"
          : "La solicitud fue modificada exitosamente"
      );
      navigate(-1);
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={onClickSave}>
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item label="Número" name="numero">
            <Input disabled={true} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Prestador" name="prestador" rules={[required]}>
            <Select showSearch optionFilterProp="children" disabled={disabled}>
              {prestadores.map((option, index) => (
                <Select.Option key={index} value={option.id}>
                  {option.razonSocial}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item
            label="Cantidad de tarjetas"
            name="tarjetas"
            rules={[required]}
          >
            <InputNumber min={1} max={1000} readOnly={disabled} />
          </Form.Item>
        </Col>
      </Row>

      <div className="actions">
        <Button type="text" onClick={onClickBack}>
          Volver
        </Button>
        {!disabled && (
          <Button type="primary" htmlType="submit" loading={loading}>
            {!loading && "Guardar"}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default SolicitudesForm;
