import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import {
  FiCheck,
  FiDollarSign,
  FiEdit2,
  FiInbox,
  FiPlus,
  FiPrinter,
  FiSearch,
  FiTrash2,
} from "react-icons/fi";

import { TableContextProvider } from "./../../store/table-context";
import { downloadBase64 } from "../../services/files";
import http from "./../../services/http";
import { confirm, modalSuccess } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";

import Card from "./../../components/ui/card";
import Header from "../../components/ui/header";
import Filters from "./../../components/ui/filters";
import Table from "../../components/ui/table";
import SolicitudesEstado from "../../components/solicitudes/solicitudes-estado";

const SolicitudesListPage = () => {
  let navigate = useNavigate();

  const VER = hasPermission(actions.SolicitudesVer);
  const CREAR = hasPermission(actions.SolicitudesCrear);
  const EDITAR = hasPermission(actions.SolicitudesCrear);
  const ELIMINAR = hasPermission(actions.SolicitudesEliminar);
  const TARJETAS_IMPRIMIR = hasPermission(actions.SolicitudesTarjetasImprimir);
  const ACTUALIZAR_PAGO = hasPermission(actions.SolicitudesActualizarPago);
  const ACTUALIZAR_ENTREGA = hasPermission(
    actions.SolicitudesActualizarEntrega
  );

  const title = "Solicitudes";
  const icon = <FiInbox />;
  const breadcrumb = [{ title: "Solicitudes", url: "/solicitudes" }];

  const filters = [
    {
      type: "input",
      label: "Numero",
      name: "numero",
    },
    {
      type: "input",
      label: "Solicitante",
      name: "solicitante",
    },
    {
      type: "date",
      label: "Fecha Desde",
      name: "desde",
      half: true,
    },
    {
      type: "date",
      label: "Fecha Hasta",
      name: "hasta",
      half: true,
    },
    {
      type: "select",
      label: "Estado",
      name: "estado",
      values: [
        { value: "0", text: "Pendiente" },
        { value: "1", text: "Pagada" },
        { value: "2", text: "Entregada" },
        { value: "3", text: "Eliminada" },
      ],
    },
  ];

  const columns = [
    { title: "Número", property: "numero", sortable: true },
    { title: "Solicitante", property: "solicitante", sortable: true },
    { title: "Fecha", property: "fecha", sortable: true },
    { title: "Tarjetas", property: "tarjetas", sortable: true },
    {
      title: "Estado",
      property: "estado",
      sortable: true,
      render: (item) => <SolicitudesEstado estado={item.estado} />,
    },
  ];

  const menu = (item) => (
    <Menu>
      {VER && (
        <Menu.Item
          key="1"
          icon={<FiSearch />}
          onClick={() => onClickView(item)}
        >
          Ver
        </Menu.Item>
      )}
      {EDITAR && (
        <Menu.Item key="2" icon={<FiEdit2 />} onClick={() => onClickEdit(item)}>
          Editar
        </Menu.Item>
      )}
      {ELIMINAR && item.estado !== 3 && (
        <Menu.Item
          key="3"
          icon={<FiTrash2 />}
          onClick={() => onClickDelete(item)}
        >
          Eliminar
        </Menu.Item>
      )}

      {TARJETAS_IMPRIMIR && (item.estado === 1 || item.estado === 2) && (
        <React.Fragment>
          <Menu.Divider />
          <Menu.Item
            key="4"
            icon={<FiPrinter />}
            onClick={() => onClickPrint(item)}
          >
            Imprimir
          </Menu.Item>
        </React.Fragment>
      )}
      {ACTUALIZAR_PAGO && item.estado === 0 && (
        <React.Fragment>
          <Menu.Divider />

          <Menu.Item
            key="5"
            icon={<FiDollarSign />}
            onClick={() => onClickUpdatePago(item)}
          >
            Actualizar Pago
          </Menu.Item>
        </React.Fragment>
      )}
      {ACTUALIZAR_ENTREGA && item.estado === 1 && (
        <React.Fragment>
          <Menu.Divider />
          <Menu.Item
            key="6"
            icon={<FiCheck />}
            onClick={() => onClickUpdateEntrega(item)}
          >
            Entregar
          </Menu.Item>
        </React.Fragment>
      )}
    </Menu>
  );

  const onClickAdd = () => {
    navigate("/solicitudes/nuevo");
  };

  const onClickView = (item) => {
    navigate(`/solicitudes/${item.id}?view=readonly`);
  };

  const onClickEdit = (item) => {
    navigate(`/solicitudes/${item.id}`);
  };

  const onClickPrint = async (item) => {
    const response = await http.get(`solicitudes/${item.id}/tarjetas/pdf`);
    if (response) {
      const data = response.data;
      downloadBase64(data.nombre, data.contenido);
    }
  };

  const onClickDelete = async (item) => {
    const confirmed = await confirm(
      "Eliminar solicitud",
      `¿Esta seguro que desea eliminar la solicitud ${item.numero}?`
    );

    if (!confirmed) {
      return;
    }

    const response = await http.delete(`solicitudes/${item.id}`);
    if (response) {
      await modalSuccess(
        "Solicitud eliminada",
        "La solicitud fue eliminada exitosamente"
      );
      navigate(0);
    }
  };

  const onClickUpdatePago = async (item) => {
    const confirmed = await confirm(
      "Confirmar Pago",
      `¿Esta seguro que desea marcar la solicitud ${item.numero} como pagada?`
    );

    if (!confirmed) {
      return;
    }

    const response = await http.patch(`solicitudes/${item.id}/pago`);
    if (response) {
      await modalSuccess(
        "Pago confirmado",
        "La solicitud fue actualizada exitosamente"
      );
      navigate(0);
    }
  };

  const onClickUpdateEntrega = async (item) => {
    const confirmed = await confirm(
      "Confirmar Entrega",
      `¿Esta seguro que desea marcar la solicitud ${item.numero} como entregada?`
    );

    if (!confirmed) {
      return;
    }

    const response = await http.patch(`solicitudes/${item.id}/entrega`);
    if (response) {
      await modalSuccess(
        "Entrega confirmada",
        "La solicitud fue actualizada exitosamente"
      );
      navigate(0);
    }
  };

  const buttons = [
    {
      title: "Agregar Solicitud",
      text: <FiPlus />,
      type: "primary",
      onClick: onClickAdd,
      visible: CREAR,
    },
  ];

  return (
    <TableContextProvider>
      <Card>
        <Header
          title={title}
          icon={icon}
          breadcrumb={breadcrumb}
          showFilters
          buttons={buttons}
        />
        <Filters fields={filters} />
        <Table
          id="table-solicitudes"
          columns={columns}
          menu={menu}
          url="/solicitudes"
          orderBy="fecha"
          orderDirection="descending"
        />
      </Card>
    </TableContextProvider>
  );
};

export default SolicitudesListPage;
