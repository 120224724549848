/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Dropdown, Menu } from "antd";
import { SpinnerCircular } from "spinners-react";
import { FiMoreVertical, FiPrinter } from "react-icons/fi";

import { downloadBase64 } from "../../services/files";
import http from "../../services/http";
import { actions, hasPermission } from "../../services/security";

import classes from "./solicitudes-tarjetas.module.scss";

import icon from "./../../assets/images/imagen.png";

const SolicitudesTarjetas = (props) => {
  const { id } = props;

  const VER = hasPermission(actions.SolicitudesTarjetasVer);
  const IMPRIMIR = hasPermission(actions.SolicitudesTarjetasImprimir);

  const [tarjetas, setTarjetas] = useState([]);
  const [printing, setPrinting] = useState();

  useEffect(() => {
    async function getForm() {
      if (id === "nuevo" || !VER) {
        return;
      }

      const response = await http.get(`solicitudes/${id}/tarjetas`);
      if (response) {
        const data = response.data;
        setTarjetas(data);
      }
    }
    getForm();
  }, [id]);

  const onClickPrint = async (item) => {
    setPrinting(item.id);

    const response = await http.get(`tarjetas/${item.id}/pdf/`);
    if (response) {
      const data = response.data;
      downloadBase64(data.nombre, data.contenido);
    }

    setPrinting();
  };

  const menu = (item) => (
    <Menu>
      {IMPRIMIR && (
        <Menu.Item
          key="1"
          icon={<FiPrinter />}
          onClick={() => onClickPrint(item)}
        >
          Imprimir
        </Menu.Item>
      )}
    </Menu>
  );

  if (id === "nuevo") {
    return <React.Fragment />;
  }

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.icon}>
          <img src={icon} alt="emails" />
        </div>
        <div className={classes.data}>
          <div className={classes.title}>Tarjetas</div>
          <div className={classes.description}>
            Lista de tarjetas solicitadas
          </div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th className={classes.actions}></th>
            <th>Número</th>
            <th>Matafuego</th>
            <th>Fecha Activación</th>
          </tr>
        </thead>
        <tbody>
          {tarjetas.length === 0 && (
            <tr>
              <td colSpan={4}>
                <div className={classes.empty}>
                  <div className={classes.title}>Sin resultados</div>
                  <div className={classes.description}>
                    No se encontró ningún resultado
                  </div>
                </div>
              </td>
            </tr>
          )}

          {tarjetas.map((tarjeta, index) => (
            <tr key={index}>
              <td className={classes.actions}>
                {printing === tarjeta.id && (
                  <SpinnerCircular
                    className={classes.loading}
                    size="20px"
                    thickness={150}
                    color="#04bebe"
                    secondaryColor="#eeedfd"
                  />
                )}
                {printing !== tarjeta.id && (
                  <Dropdown
                    overlay={() => menu(tarjeta)}
                    placement="bottomLeft"
                    arrow
                    trigger="click"
                  >
                    <div className={classes.menu}>
                      <FiMoreVertical />
                    </div>
                  </Dropdown>
                )}
              </td>
              <td>{tarjeta.numero}</td>
              <td>{tarjeta.matafuego}</td>
              <td>{tarjeta.activacion}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SolicitudesTarjetas;
