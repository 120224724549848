import { NavLink } from "react-router-dom";
import {
  FiHome,
  FiImage,
  FiInbox,
  FiMonitor,
  FiShield,
  FiUser,
} from "react-icons/fi";
import SimpleBar from "simplebar-react";

import { actions, hasPermission } from "./../../services/security";

import classes from "./sidebar.module.scss";

import logo from "./../../assets/images/logo.png";

const Sidebar = () => {
  const sections = [
    {
      links: [
        {
          title: "Inicio",
          url: "/",
          icon: <FiHome />,
          visible: true,
        },
      ],
    },
    {
      title: "Extintores",
      links: [
        {
          title: "Solicitudes",
          url: "/solicitudes",
          icon: <FiInbox />,
          visible: hasPermission(actions.SolicitudesVer),
        },
        {
          title: "Tarjetas",
          url: "/tarjetas",
          icon: <FiImage />,
          visible: hasPermission(actions.TarjetasVer),
        },
        {
          title: "Prestadores",
          url: "/prestadores",
          icon: <FiUser />,
          visible: hasPermission(actions.PrestadoresVer),
        },
      ],
    },
    {
      title: "Seguridad",
      links: [
        {
          title: "Perfiles",
          url: "/perfiles",
          icon: <FiShield />,
          visible: hasPermission(actions.PerfilesVer),
        },
        {
          title: "Usuarios",
          url: "/usuarios",
          icon: <FiUser />,
          visible: hasPermission(actions.UsuariosVer),
        },
        {
          title: "Auditoría",
          url: "/auditorias",
          icon: <FiMonitor />,
          visible: hasPermission(actions.AuditoriasVer),
        },
      ],
    },
  ];

  return (
    <div className={classes.sidebar}>
      <div className={classes.brand}>
        <img className={classes.logo} src={logo} alt="logo" />
        <div className={classes.title}>
          <div className={classes.small}>Sistema de Gestión</div>
          <div className={classes.name}>Extintores</div>
        </div>
      </div>
      <SimpleBar style={{ maxHeight: "calc(100vh - 65px)" }}>
        <div className={classes.sections}>
          {sections.map(
            (s, index) =>
              s.links.filter((x) => x.visible).length > 0 && (
                <div key={index} className={classes.section}>
                  <div className={classes.title}>{s.title}</div>
                  {s.links.map(
                    (i, lIndex) =>
                      i.visible && (
                        <NavLink
                          key={lIndex}
                          className={({ isActive }) =>
                            isActive
                              ? `${classes.link} ${classes.active}`
                              : `${classes.link}`
                          }
                          to={i.url}
                        >
                          <div className={classes.icon}>{i.icon}</div>
                          <div className={classes.title}>{i.title}</div>
                        </NavLink>
                      )
                  )}
                </div>
              )
          )}
        </div>
      </SimpleBar>
    </div>
  );
};

export default Sidebar;
