/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Row } from "antd";
import InputMask from "react-input-mask";

import { cuit, email, required } from "../../services/forms";
import http from "../../services/http";
import { modalSuccess } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";

const PrestadoresForm = (props) => {
  let navigate = useNavigate();
  const [form] = Form.useForm();

  const { id, readonly } = props;

  const CREAR = hasPermission(actions.PrestadoresCrear);
  const EDITAR = hasPermission(actions.PrestadoresEditar);
  const disabled = readonly || (id === "nuevo" ? !CREAR : !EDITAR);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getForm() {
      if (id === "nuevo") {
        return;
      }

      const response = await http.get(`prestadores/${id}`);
      if (response) {
        const data = response.data;
        form.setFieldsValue(data);
      }
    }
    getForm();
  }, [id]);

  const onClickBack = () => {
    navigate(-1);
  };

  const onClickSave = async (values) => {
    setLoading(true);

    values.cuit = values.cuit.replace(/-/g, "");

    const response =
      id === "nuevo"
        ? await http.post("prestadores", values)
        : await http.put(`prestadores/${id}`, values);

    setLoading(false);

    if (response) {
      await modalSuccess(
        "Prestador guardado",
        id === "nuevo"
          ? "El prestador fue creado exitosamente"
          : "El prestador fue modificado exitosamente"
      );
      navigate(-1);
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={onClickSave}>
      <Row gutter={16}>
        <Col xs={24} lg={16}>
          <Form.Item label="Razón Social" name="razonSocial" rules={[required]}>
            <Input maxLength={100} disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item label="CUIT" name="cuit" rules={[required, cuit]}>
            <InputMask mask="99-99999999-9" maskChar=" " disabled={disabled}>
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Dirección" name="direccion">
            <Input maxLength={250} disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24} lg={16}>
          <Form.Item label="Email" name="email" rules={[required, email]}>
            <Input maxLength={100} disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item label="Teléfono" name="telefono">
            <InputMask mask="9999999999" maskChar=" " disabled={disabled}>
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Comentario" name="comentario">
            <Input.TextArea
              showCount
              maxLength={500}
              rows={5}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>

      <div className="actions">
        <Button type="text" onClick={onClickBack}>
          Volver
        </Button>
        {!disabled && (
          <Button type="primary" htmlType="submit" loading={loading}>
            {!loading && "Guardar"}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default PrestadoresForm;
