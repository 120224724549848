/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Menu } from "antd";
import { SpinnerCircular } from "spinners-react";
import { FiDownload, FiMoreVertical, FiTrash2 } from "react-icons/fi";

import { downloadBase64, readFileAsBase64 } from "./../../services/files";
import http from "../../services/http";
import { confirm } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";

import classes from "./prestadores-archivos.module.scss";

import icon from "./../../assets/images/folder.png";

const PrestadoresArchivos = (props) => {
  const { id, readonly } = props;
  const fileRef = useRef(null);

  const VER = hasPermission(actions.PrestadoresArchivosVer);
  const AGREGAR = hasPermission(actions.PrestadoresArchivosAgregar);
  const ELIMINAR = hasPermission(actions.PrestadoresArchivosEliminar);

  const [archivos, setArchivos] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState();

  useEffect(() => {
    async function getForm() {
      if (id === "nuevo" || !VER) {
        return;
      }

      const response = await http.get(`prestadores/${id}/archivos`);
      if (response) {
        const data = response.data;
        setArchivos(data);
      }
    }
    getForm();
  }, [id, reload]);

  const onClickDownload = async (item) => {
    setDownloading(item.id);

    const response = await http.get(`archivos/${item.id}`);
    if (response) {
      const data = response.data;
      downloadBase64(data.nombre, data.contenido);
    }

    setDownloading();
  };

  const onClickAdd = () => {
    fileRef.current.click();
  };

  const onChangeFiles = async (e) => {
    const files = e.target.files;
    if (files.length === 0) {
      return;
    }

    setLoading(true);
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const base64 = await readFileAsBase64(file);

      const url = `prestadores/${id}/archivos`;
      const data = {
        id: id,
        nombre: file.name,
        tamanio: file.size.toString(),
        base64: base64,
      };

      await http.post(url, data);
    }

    setLoading(false);
    setReload(!reload);
  };

  const onClickDelete = async (item) => {
    const confirmed = await confirm(
      "Eliminar archivo",
      `¿Esta seguro que desea eliminar el archivo ${item.nombre}?`
    );

    if (!confirmed) {
      return;
    }

    const response = await http.delete(`prestadores/${id}/archivos/${item.id}`);
    if (response) {
      setReload(!reload);
    }
  };

  const menu = (item) => (
    <Menu>
      <Menu.Item
        key="1"
        icon={<FiDownload />}
        onClick={() => onClickDownload(item)}
      >
        Descargar
      </Menu.Item>
      {ELIMINAR && !readonly && (
        <Menu.Item
          key="2"
          icon={<FiTrash2 />}
          onClick={() => onClickDelete(item)}
        >
          Eliminar
        </Menu.Item>
      )}
    </Menu>
  );

  if (id === "nuevo") {
    return <React.Fragment />;
  }

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.icon}>
          <img src={icon} alt="emails" />
        </div>
        <div className={classes.data}>
          <div className={classes.title}>Archivos</div>
          <div className={classes.description}>
            Adjuntá toda la documentación correspondiente al prestador.
          </div>
        </div>
      </div>

      {archivos.length === 0 && (
        <div className={classes.empty}>
          <div className={classes.title}>Sin resultados</div>
          <div className={classes.description}>
            No se ha adjuntado ningun archivo aún
          </div>
        </div>
      )}

      <div className={classes.list}>
        {archivos.map((archivo, index) => (
          <div key={index} className={classes.item}>
            {downloading === archivo.id && (
              <SpinnerCircular
                className={classes.loading}
                size="30px"
                thickness={150}
                color="#04bebe"
                secondaryColor="#eeedfd"
              />
            )}
            {downloading !== archivo.id && (
              <Dropdown
                overlay={() => menu(archivo)}
                placement="bottomLeft"
                arrow
                trigger="click"
              >
                <div className={classes.menu}>
                  <FiMoreVertical />
                </div>
              </Dropdown>
            )}
            <div className={classes.content}>
              <div>
                <div className={classes.title}>{archivo.nombre}</div>
                <div className={classes.size}>{archivo.tamanio}</div>
              </div>
              <div>
                <div className={classes.date}>{archivo.fecha}</div>
                <div className={classes.time}>{archivo.hora}</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {AGREGAR && !readonly && (
        <div className={classes.actions}>
          <Button type="primary" loading={loading} onClick={onClickAdd}>
            {!loading && "Adjuntar"}
          </Button>
          <input
            type="file"
            multiple={true}
            ref={fileRef}
            onChange={onChangeFiles}
          />
        </div>
      )}
    </div>
  );
};

export default PrestadoresArchivos;
