export const formatCurrency = (value) => {
  if (!value) {
    value = 0;
  }

  const formatter = new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 0,
  });

  return formatter.format(value).replace(/^(\D+)/, "$1 ");
};
