import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import {
  FiCheckCircle,
  FiEdit,
  FiMail,
  FiPlus,
  FiSearch,
  FiTrash2,
  FiUser,
} from "react-icons/fi";

import http from "../../services/http";
import { confirm, modalSuccess } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";
import { TableContextProvider } from "../../store/table-context";

import Card from "../../components/ui/card";
import Header from "../../components/ui/header";
import Filters from "../../components/ui/filters";
import Table from "../../components/ui/table";
import PrestadoresEstado from "./../../components/prestadores/prestadores-estado";

const PrestadoresListPage = () => {
  let navigate = useNavigate();

  const VER = hasPermission(actions.PrestadoresVer);
  const CREAR = hasPermission(actions.PrestadoresCrear);
  const EDITAR = hasPermission(actions.PrestadoresEditar);
  const ELIMINAR = hasPermission(actions.PrestadoresEliminar);
  const ENVIAR_CREDENCIALES = hasPermission(
    actions.PrestadoresCredencialesEnviar
  );

  const title = "Prestadores";
  const icon = <FiUser />;
  const breadcrumb = [{ title: "Prestadores", url: "/prestadores" }];

  const filters = [
    {
      type: "input",
      label: "Razón Social",
      name: "razonSocial",
    },
    {
      type: "input",
      label: "Cuit",
      name: "cuit",
    },
    {
      type: "select",
      label: "Estado",
      name: "estado",
      values: [
        {
          value: "0",
          text: "Habilitado",
        },
        {
          value: "1",
          text: "Deshabilitado",
        },
      ],
    },
  ];

  const columns = [
    { title: "Razón Social", property: "razonSocial", sortable: true },
    { title: "Cuit", property: "cuit", sortable: true },
    {
      title: "Estado",
      property: "habilitado",
      sortable: false,
      render: (item) => <PrestadoresEstado estado={item.estado} />,
    },
  ];

  const menu = (item) => (
    <Menu>
      {VER && (
        <Menu.Item
          key="1"
          icon={<FiSearch />}
          onClick={() => onClickView(item)}
        >
          Ver
        </Menu.Item>
      )}
      {EDITAR && (
        <Menu.Item key="2" icon={<FiEdit />} onClick={() => onClickEdit(item)}>
          Editar
        </Menu.Item>
      )}
      {ELIMINAR && (
        <Menu.Item
          key="3"
          icon={item.estado === 0 ? <FiTrash2 /> : <FiCheckCircle />}
          onClick={() => onClickDelete(item)}
        >
          {item.estado === 0 ? "Deshabilitar" : "Habilitar"}
        </Menu.Item>
      )}
      {ENVIAR_CREDENCIALES && (
        <React.Fragment>
          <Menu.Divider />
          <Menu.Item
            key="4"
            icon={<FiMail />}
            onClick={() => onClickSendCredentials(item)}
          >
            Enviar Credenciales
          </Menu.Item>
        </React.Fragment>
      )}
    </Menu>
  );

  const onClickAdd = () => {
    navigate("/prestadores/nuevo");
  };

  const onClickView = (item) => {
    navigate(`/prestadores/${item.id}?view=readonly`);
  };

  const onClickEdit = (item) => {
    navigate(`/prestadores/${item.id}`);
  };

  const onClickDelete = async (item) => {
    const action = item.estado === 0 ? "Deshabilitar" : "Habilitar";
    const result = item.estado === 0 ? "deshabilitado" : "habilitado";

    const confirmed = await confirm(
      `${action} prestador`,
      `¿Esta seguro que desea ${action.toLowerCase()} al prestador ${
        item.razonSocial
      }?`
    );

    if (!confirmed) {
      return;
    }

    const response = await http.delete(`prestadores/${item.id}`);
    if (response) {
      await modalSuccess(
        `Prestador ${result}`,
        `El prestador fue ${result} exitosamente`
      );
      navigate(0);
    }
  };

  const onClickSendCredentials = async (item) => {
    const confirmed = await confirm(
      `Enviar credenciales`,
      `¿Esta seguro que desea enviarle sus credenciales al prestador ${item.razonSocial}?`
    );

    if (!confirmed) {
      return;
    }

    const response = await http.patch(`prestadores/${item.id}/credenciales`);
    if (response) {
      await modalSuccess(
        `Credenciales enviadas`,
        `Las credenciales del prestador fueron enviadas exitosamente`
      );
    }
  };

  const buttons = [
    {
      title: "Agregar Prestador",
      text: <FiPlus />,
      type: "primary",
      onClick: onClickAdd,
      visible: CREAR,
    },
  ];

  return (
    <TableContextProvider>
      <Card>
        <Header
          title={title}
          icon={icon}
          breadcrumb={breadcrumb}
          showFilters
          buttons={buttons}
        />
        <Filters fields={filters} />
        <Table
          id="table-prestadores"
          columns={columns}
          menu={menu}
          url="/prestadores"
          orderBy="nombre"
          orderDirection="ascending"
        />
      </Card>
    </TableContextProvider>
  );
};

export default PrestadoresListPage;
